import { api } from '@/shared/api'

const userManagement = {
  fetchUser: async payload => {
    return await api.get(`user/${payload.user_id}`)
  },

  fetchActiveUsers: async () => {
    let response = await api.get('users')

    return response.map(u => ({
      id: u.id,
      name: u.full_name,
      label: u.full_name
    }))
  },
  fetchUserBenefits: async payload => {
    let res = await api.get(`user/${payload.parentId}/benefits`)

    return res
  },
  saveUserBenefits: async payload => {
    return api.put(`user/benefits`, payload)
  },
  fetchUserGroups: async () => {
    let response = await api.get('groups')

    return response.map(u => ({
      id: u.groupid,
      name: u.groupname,
      label: u.groupname
    }))
  },
  fetchGroupedUsers: async () => {
    return await api.get('groups/users/mapped')
  },
  fetchGroupsWithUsers: async () => {
    let users = await api.get('users')

    let groups = await api.get('groups')

    let result = [
      {
        group_label: 'Groups',
        group_values: groups.map(u => ({
          id: u.groupid.toString(),
          label: u.groupname.trim()
        }))
      },
      {
        group_label: 'Users',
        group_values: users.map(u => ({
          id: u.id.toString(),
          label: u.full_name.trim()
        }))
      }
    ]

    return result
  },

  //fetchUserBonusPayments: async payload => {
  //  return api.get(`user/${payload.parentId}/bonus-payments`)
  //},
  fetchBonusPayments: async payload => {
    //return await api.post('bonus-payments')

    console.log('fetchBonusPayments', JSON.stringify(payload))
    let url = ''
    let method = ''

    if (payload.parentId) {
      url = `user/${payload.parentId}/bonus-payments`
      method = 'get'
    } else {
      url = `bonus-payments`
      method = 'post'
    }
    console.log('fetchBonusPayments', method, url, payload)
    return api[method](url, payload)
  },
  saveBonusPayment: async payload => {
    console.log('saveBonusPayment', JSON.stringify(payload))

    let url = payload.ID ? `bonus-payments/${payload.ID}` : `bonus-payments`

    return api.put(url, payload)
  },
  deleteBonusPayment: async payload => {
    return await api.delete(`bonus-payments/${payload}`)
  }
}

export default userManagement
